import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import {
  FaCalendarDay,
  FaLaptop,
  FaRegClipboard,
  FaClock,
  FaServer,
  FaKeyboard,
  FaPassport,
  FaLock,
  FaCheck,
  FaExchangeAlt,
  FaFileCode,
  FaMobile,
} from "react-icons/fa";
import Columns from "../components/columns";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Tile from "../components/Tile";
import Container from "../components/container";
import { Link } from "gatsby";
import Hero from "../components/HeroBanner";
import * as styles from "./index.module.css";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import Lightbox from "../components/lightBox";
import {
  FcAcceptDatabase,
  FcAutomatic,
  FcBinoculars,
  FcCalendar,
  FcCollaboration,
  FcComboChart,
  FcConferenceCall,
  FcDatabase,
  FcDocument,
  FcDoughnutChart,
  FcDownload,
  FcFeedback,
  FcFile,
  FcIphone,
  FcMultipleInputs,
  FcRating,
  FcSettings,
  FcSynchronize,
  FcTimeline,
  FcTodoList,
  FcViewDetails,
  FcNews,
  FcWorkflow,
} from "react-icons/fc";



export const query = graphql`
  {
    aura: file(relativePath: { eq: "logo-aura.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, layout: CONSTRAINED)
      }
    }
    synergius: file(relativePath: { eq: "synergius-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, layout: CONSTRAINED)
      }
    }
    laptop: file(relativePath: { eq: "laptop-aplikacja-crm.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
     szanse: file(relativePath: { eq: "kanban-szanse.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
     pulpit: file(relativePath: { eq: "pulpit-sprzedaz.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    kontrahenci: file(relativePath: { eq: "karta-firmy.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    heroBg: file(
      relativePath: { eq: "system-crm-questy.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 85, layout: FULL_WIDTH)
      }
    }
  }
`;


const Aura = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="System CRM - Wsparcie Sprzedaży i Marketingu"
        description="System CRM dla MŚP i dla dużych organizacji ✅ Prosty i gotowy system CRM oraz rozwiązania dedykowane • Czym jest system CRM? • Komplet informacji o wdrożeniach CRM w jednym miejscu"
      />
         <Hero heroImage={data.heroBg} justifyContent="center">
        <h1>System CRM w sprzedaży i marketingu</h1>
        <h2>Usprawnij zarządzanie relacjami z klientem</h2>
      </Hero>
   <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
      }}
    >
      <div className="container container--text">
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz bezpłatnie wypróbować system CRM w Twojej firmie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i wejść na wyższy poziom sprzedaży
            </Link>
          </div>
          </div>
    </section>
       <Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
<p>
Wiemy, jak ważne jest zapewnienie wysokiej jakości obsługi. To głównie od niej zależy, 
to jak będą rozwijać się relacje z kontrahentami. <strong>Tworząc własne systemy CRM bardzo dużą 
uwagę przykładamy do tego, aby były one wygodnym narzędziem i potężną maszyną sprzedażową w rękach handlowców. </strong>
</p>
</div>
</div>
</Container>
<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
<p>
Tworzymy systemy CRM o których można powiedzieć, że są <strong>proste i&nbsp;gotowe, czyli programy funkcjonalne, ale łatwe w 
obsłudze i gotowe do szybkiego wdrożenia</strong> (<a href="https://synergiuscrm.pl/">Synergius CRM</a>). Specjalizujemy się również w&nbsp;<strong>dedykowanych systemach CRM, 
które w pełni dopasowujemy do potrzeb i wizji konkretnego przedsiębiorstwa</strong> (<a href="https://aurabusiness.pl/">Aura Business</a>), często rozszerzając jego zakres 
poza standardowe funkcjonalności.
</p>
<p>
Jak to możliwe, że od lat wspieramy zarówno małe, ale prężnie rozwijające się 
przedsiębiorstwa oraz duże, międzynarodowe organizacje? Słuchamy naszych klientów, a w 
projektowanych przez nas funkcjach skupiamy się na zapewnieniu elastyczności. Wiemy również, że 
to narzędzie powinno dopasować się do firmy, a nie na odwrót. To podejście pozwala nam oferować systemy CRM 
dopasowane do firm o zróżnicowanych potrzebach.
</p>
</div>
 <div className="text-block" style={{
          paddingTop: 35,
        }}>
            <Lightbox
        style={{
          maxWidth: 1000,
          margin: "0 auto",
        }}
        images={[data.laptop]}
        alts={["System CRM - Questy"]}
      />
</div>
</div>
</Container>
 <section
      className={styles.pricingSection}
      style={{
        color: "rgb(0, 0, 0)",
        padding: 20,
      }}
    >
 <Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
<p><strong>Jakie problemy rozwiązują nasze systemy CRM?</strong></p>
<p><ul>
<li>Brak standardu obsługi klienta, samowola sprzedażowa o&nbsp;niskiej skuteczności.</li>
<li>Błędne ofertowanie i rabatowanie tego samego klienta przez różne osoby.</li>
<li>Brak wiedzy o tym, dlaczego tracicie potencjalnych klientów.</li>
<li>Brak pomysłów na usprawnienie oferty i obsługi klienta. </li>
<li>Trudność w realnej ocenie efektów i aktywności przedstawicieli handlowych. </li>
<li>Chaos i rozproszenie informacji o kontrahentach.</li>
<li>Trudność w zastępowaniu, tylko jeden pracownik wie o&nbsp;czym&nbsp;z klientem rozmawiał i co ustalił. </li>
<li>Brak standardu pracy i trudność w jego wprowadzeniu. </li>
<li>Brak dobrej segmentacji klientów, która mogłaby zostać wykorzystana np. do reaktywacji utraconych szans.</li>
<li>Niespójny wygląd dokumentów: ofert, zamówień i faktur. </li>
<li>Raportowanie i analiza sprzedaży wymaga ręcznego zbierania danych, co jest czasochłonne. </li>
</ul></p>
</div>
  <div className="text-block">
  <p><strong>Jakich korzyści możesz spodziewać się po wdrożeniu systemu CRM?</strong></p>
<p><ul>
<li>Poprawę w relacjach z klientami. </li>
<li>Ujednolicenie standardu obsługi klienta, co pozwoli Ci wdrażać nowe pomysły i obserwować ich wpływ na sprzedaż.</li>
<li>Zauważalną oszczędność czasu pracowników np. generowanie dokumentów będzie znacznie prostsze niż obecnie.</li>
<li>Uporządkowaną i spójną bazę wiedzy o całej historii współpracy z&nbsp;kontrahentami, co ułatwi zastępowalność w&nbsp;przypadku nieobecności pracownika. </li>
<li>Znajomość przyczyn rezygnacji potencjalnych klientów, co pozwoli Ci usprawnić ofertę.</li>
<li>Wygodne i proste monitorowanie działań sprzedażowych przez managera. </li>
<li>Automatyczne tworzenie raportów sprzedażowych i&nbsp;dostęp do aktualnych statystyk w dowolnym momencie. </li>
<li>Usprawnienie komunikacji wewnątrz organizacji. </li>
<li>Bezpieczeństwa informacji, tylko uprawnione osoby będą miały dostęp do wrażliwych danych.</li>
<li>Sprawniejsze pozyskiwanie klientów i wzrost sprzedaży.</li>
<li>Możliwość zauważenia problemu na wczesnym etapie. </li>
<li>Prostsze prognozowanie wyników.</li>
<li>Sprawne narzędzie do oceny efektywności pracy poszczególnych handlowców. </li>
</ul></p>
              
</div>
</div>
</Container>
</section>
 <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
      }}
    >
      <div className="container container--text">
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz bezpłatnie wypróbować system CRM w&nbsp;Twojej firmie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i wejść na wyższy poziom sprzedaży
            </Link>
          </div>
          </div>
    </section>

   <Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
 <h2
 style={{
  paddingTop:"30px",
  paddingBottom:"30px",
}}
  >Jakie funkcje posiadają nasze systemy CRM? </h2>

<p>Zadaniem systemu CRM jest wsparcie firmy w sprawnej obsłudze procesów sprzedażowych na każdym etapie. 
System ma managerom sprzedaży ułatwić monitorowanie całego procesu i oszczędzić czas. Natomiast dla handlowców jest 
drogowskazem w obsłudze klienta i budowaniu z nim relacji. Jakich możliwości potrzebują obie role?
</p>
</div>

</div>
</Container>
<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
<h3>Zarządzanie szansami sprzedaży </h3>

<p>Czasami możesz spotkać się z nazwą zarządzanie procesem lub lejkiem sprzedaży. 
To właśnie ta mapa po której poruszają się handlowcy. Lejek podzielony jest na etapy, 
które oczywiście możesz dostosować do swoich potrzeb. Na każdym etapie lejka handlowiec ma do 
wykonania określone zadania, które również to Ty definiujesz. W ten sposób, każda szansa sprzedaży jest 
obsługiwana w ten sam, najlepszy na ten moment, sposób. 
Co to umożliwia?
Porównanie wyników, sprawne wprowadzanie usprawnień i monitorowanie efektów. 
</p>
</div>
    <div className="text-block">
    <Lightbox
        style={{
          maxWidth: 1000,
          margin: "0 auto",
        }}
        images={[data.szanse]}
        alts={[
          "Zarządzanie szansami sprzedaży w systemie CRM Questy",
        ]}
      />
    </div>
</div>
</Container>
 <Container>
         <div className="text-block">
 <p>
<strong>Czym jest ten widok dla kierownika?</strong><br />
Narzędziem do monitorowania jak idzie w sprzedaży. Tak naprawdę, wystarczy zerknąć, aby zobaczyć ile i o jakiej wartości potencjalnych szans jest na każdym z etapów, co ułatwia m.in. prognozowanie. Ikony o określonych kolorach dają sygnał: zielone - wszystko idzie zgodnie z planem, czerwone - uwaga: nie kontaktujemy się. Nie musisz się domyślać ani tracić czasu na kontakt z każdym z osobna. 
</p>
</div>
</Container>
 <Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
 <Lightbox
        style={{
          maxWidth: 1000,
          margin: "0 auto",
        }}
        images={[data.pulpit]}
        alts={[
          "Pulpit - analiza sprzedaży - systemy CRM Questy",
        ]}
      />
</div>
    <div className="text-block">
    <h3>Dashboard managera</h3>

<p>To pulpit, który zobaczysz od razu po zalogowaniu się do systemu. Co w nim wyjątkowego? To miejsce, w którym będą wyświetlać się wybrane przez Ciebie np. raporty sprzedażowe, poziom realizacji celów przez handlowców, zaplanowane spotkania na dziś czy wykonane zadania Twoich pracowników.
</p>
<p>W tym miejscu managerowie sprzedaży wyświetlają kluczowe KPI, które chcą obserwować, aby mieć rękę na pulsie każdego dnia. W ten sposób, bardzo szybko, bez skomplikowanych i czasochłonnych obliczeń mogą obserwować aktualną sytuację firmy i szybko reagować. 
</p>
    </div>
</div>
</Container>

<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">

<h3>Baza wiedzy o współpracy z kontrahentami</h3>

<p>Uporządkowane miejsce, gdzie znajduje się kompletna historia współpracy z&nbsp;kontrahentem? Zapiski rozmów, ustaleń, przygotowane oferty, zamówienia, faktury - dostępne na wyciągnięcie ręki? To nie marzenie, wystarczy umiejętnie wykorzystać system CRM w firmie. 
</p>
<p>Co zyskasz, dzięki takiej bazie informacji? Ułatwisz pracę każdej osobie w&nbsp;Twojej firmie, której zadaniem jest kontakt z klientem. Umożliwisz zastępowalność nieobecnych pracowników przy zachowaniu wysokiej jakości obsługi klienta. Zadbasz o to, aby wiedza należała do firmy i nie zniknęła wraz z odejściem pracownika.
</p>
</div>
    <div className="text-block">
  <Lightbox
        style={{
          maxWidth: 1000,
          margin: "0 auto",
        }}
        images={[data.kontrahenci]}
        alts={[
          "Baza wiedzy o kontrahentach - baza CRM - systemie CRM Questy",
        ]}
      />
    </div>
</div>
</Container>
<h3
style={{
  paddingTop:"30px",
  paddingBottom:"30px",
}}>Inne przykładowe funkcje CRM</h3>

  <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Tile>
            <FcAcceptDatabase size="70px" />
            <br />
            <h3>Zarządzanie szansami sprzedaży</h3>
            <p>Uporządkowany proces podzielony na etapy i cele</p>
          </Tile>
           <Tile>
            <FcConferenceCall size="70px" />
            <br />
            <h3>Baza wiedzy o&nbsp;kontrahentach</h3>
            <p>Kompletna historia współpracy w jednym miejscu</p>
          </Tile>

          <Tile>
            <FcCalendar size="70px" color="#999999" />
            <br />
            <h3>Planowanie i&nbsp;koordynacja pracy</h3>
            <p>
              Monitorowanie aktywności i&nbsp;szybkie przekazywanie zadań
            </p>
          </Tile>
          <Tile>
            <FcSynchronize size="70px" color="#999999" />
            <br />
            <h3>Integracja z&nbsp;systemem ERP</h3>
            <p>
              Bezpieczna wymiana danych z Twoim programem handlowo-magazynowym
            </p>
          </Tile>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Tile>
            <FcComboChart size="70px" color="#999999" />
            <br />
            <h3>Raporty dla zarządu</h3>
            <p>Automatyczne i aktualne raporty sprzedażowe</p>
          </Tile>
         <Tile>
            <FcDoughnutChart size="70px" color="#999999" />
            <br />
            <h3>Raporty dla handlowców</h3>
            <p>Automtyczne zestawienia indywidualnych wyników</p>
          </Tile>
           <Tile>
            <FcCollaboration size="70px" color="#999999" />
            <br />
            <h3>Uporządkowana komunikacja</h3>
            <p>
              Sprawne przekazywanie informacji w&nbsp;całej firmie
            </p>
          </Tile>
          <Tile>
            <FcIphone size="70px" color="#999999" />
            <br />
            <h3>Aplikacja mobilna</h3>
            <p>Dostęp do kontaktów&nbsp; zawsze pod ręką</p>
          </Tile>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Tile>
            <FcDatabase size="70px" color="#999999" />
            <br />
            <h3>Katalog produktów</h3>
            <p>
              Wspólna baza informacji o&nbsp;stanach magazynowych i&nbsp;cenach
            </p>
          </Tile>

          <Tile>
            <FcViewDetails size="70px" color="#999999" />
            <br />
            <h3>Zarządzanie ofertowaniem</h3>
            <p>
              Spójny standard tworzenia ofert handlowych dla całej firmy
            </p>
          </Tile>
          <Tile>
            <FcFile size="70px" color="#999999" />
            <br />
            <h3>Zarządzanie zamówieniami</h3>
            <p>Szybkie przenoszenie pozycji z oferty handlowej na zamówienie</p>
          </Tile>

          <Tile>
            <FcDocument size="70px" color="#999999" />
            <br />
            <h3>Fakturowanie i&nbsp;płatności</h3>
            <p>
              Możliwość wystawiania dokumentów faktur i&nbsp;płatności
            </p>
          </Tile>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Tile>
            <FcFeedback size="70px" color="#999999" />
            <br />
            <h3>E-mail</h3>
            <p>
              Szybki dostęp do korespondecji klienta z&nbsp;opiekunem handlowym
            </p>
          </Tile>
          <Tile>
            <FcTimeline size="70px" color="#999999" />
            <br />
            <h3>Zarządzanie projektami</h3>
            <p>Sprawne zarządzanie pracą zespołu</p>
          </Tile>
          <Tile>
            <FcRating size="70px" color="#999999" />
            <br />
            <h3>
              Cele <br />
              sprzedażowe
            </h3>
            <p>Narzędzie do motywowania pracowników</p>
          </Tile>
          <Tile>
            <FcBinoculars size="70px" color="#999999" />
            <br />
            <h3>Mapy i lokalizacja</h3>
            <p>
              Wygodne planowanie wyjazdów handlowych
            </p>
          </Tile>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Tile>
            <FcWorkflow size="70px" color="#999999" />
            <br />
            <h3>Dostosowanie systemu</h3>
            <p>Możliwość samodzielnej konfiguracji wielu obszarów systemu</p>
          </Tile>
          <Tile>
            <FcSettings size="70px" color="#999999" />
            <br />
            <h3>Zarządzanie uprawnieniami</h3>
            <p>Bezpieczne przechowywanie informacji</p>
          </Tile>
          <Tile>
            <FcDownload size="70px" color="#999999" />
            <br />
            <h3>Integracja z GUS</h3>
            <p>
              Szybkie uzupełnianie danych o kontrahencie
            </p>
          </Tile>

          <Tile>
            <FcMultipleInputs size="70px" color="#999999" />
            <br />
            <h3>Integracja z wieloma kalendarzami</h3>
            <p>
              Synchronizacja kalendarzy z&nbsp;wielu urządzeń przez protokół
              CalDav
            </p>
          </Tile>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Tile>
            <FcTodoList size="70px" color="#999999" />
            <br />
            <h3>Operacje masowe</h3>
            <p>Szybkie wykonywanie działań na wielu rekordach jednocześnie</p>
          </Tile>

          <Tile>
            <FcAutomatic size="70px" color="#999999" />
            <br />
            <h3>Automatyzator</h3>
            <p>
              Oszczędność czasu dzięki automatyzacji prostych i powtarzalnych
              czynności
            </p>
          </Tile>
          <Tile>
            <FcNews size="70px" color="#999999" />
            <br />
            <h3>Ogłoszenia i aktualności firmowe</h3>
            <p>
              Idealne miejsce na dzielenie się sukcesami i nowościami
            </p>
          </Tile>
        </div>
      </Container>
  <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
      }}
    >
      <div className="container container--text">
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz bezpłatnie wypróbować system CRM w Twojej firmie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i wejść na wyższy poziom sprzedaży
            </Link>
          </div>
          </div>
          </section>
<h2
style={{
  paddingTop:"30px",
  paddingBottom:"30px",
}}>Czym wyróżniają się nasze rozwiązania?</h2>
 <Container>
      <BenefitBlockWrapper>
        <BenefitBlock
          icon={<FaFileCode size="70px" color="#86BC2A" />}
          title="Wysoka elastyczność rozwiązań"
        />
        <BenefitBlock
          icon={<FaExchangeAlt size="70px" color="#86BC2A" />}
          title="Integracje z systemami ERP"
        />
        <BenefitBlock
          icon={<FaClock size="70px" color="#86BC2A" />}
          title="Szybkie wdrożenie"
        />
        <BenefitBlock
          icon={<FaMobile size="70px" color="#86BC2A" />}
          title="Mobilność naszych systemów"
        />
      </BenefitBlockWrapper>
    </Container>
<h2
style={{
  paddingTop:"30px",
  paddingBottom:"30px",
}}>Czym różni się Synergius CRM od Aura Business? Dla kogo to produkty?</h2>

<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
<p>Synergius CRM, jak już wspominaliśmy, jest rozwiązaniem prostym i&nbsp;gotowym. Przeznaczony jest dla firm, które oczekują określonego zestawu możliwości, szybkiego wdrożenia i relatywnie niskiego kosztu utrzymania. Często klientem naszego systemu są firmy sektora MŚP oraz duże organizacje, które zaczynają współpracę z nami od produktu, który mogą wdrożyć od razu i stopniowo rozwijać w kierunku rozwiązania dedykowanego, jakim jest Aura Business. </p>
</div>
 <div className="text-block"  style={{
                textAlign: "center",
                margin: "10px",
                      paddingTop: "40px",

              }}
            >
        
<a href="https://synergiuscrm.pl/">
            <GatsbyImage
              image={data.synergius.childImageSharp.gatsbyImageData}
              style={{
                width: "100%",
                margin: "0 auto",
                maxWidth: 270, 
                              
              }}
              alt="System CRM - szybkie wdrożenie - Synergius CRM"
            />
          </a>
 </div>
</div>
</Container>
<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
<p>Aura Business to nie tylko system CRM. To dedykowane rozwiązanie dla dużych organizacji o określonej specyfice działania, która wymaga wprowadzenia szeregu rozwiązań indywidualnie dopasowanych do tego przedsiębiorstwa. Aura Business mieści w sobie system CRM, Service Management oraz E-Commerce. Można wykorzystać tylko jeden obszar lub&nbsp;rozbudować rozwiązanie w taki sposób, aby wspierało całą firmę. 


</p>
</div>
 <div className="text-block"  style={{
                textAlign: "center",
                margin: "10px",
                paddingTop: "40px",

              }}
          >
<a href="https://aurabusiness.pl/">
            <GatsbyImage
              image={data.aura.childImageSharp.gatsbyImageData}
              style={{
                width: "100%",
                margin: "0 auto",
                maxWidth: 270, 
                              
              }}
              alt="Dedykowany system CRM - Aura Business"
            />
          </a>
</div>
</div>
</Container>
<Container className="container container--text">
<h2
style={{
  paddingTop:"30px",
  paddingBottom:"30px",
  }}
  >Lubimy dzielić się wiedzą - nagrywamy dla Ciebie </h2>
    <div className="text-answers">
         <div className="text-block">
         <p>Jesteśmy doświadczonym zespołem, który nie boi się wyzwań, a uwierz, że wystąpienie przed kamerą właśnie nim było :) Tworzymy filmy związane ze sprzedażą i obsługą klienta bo wierzymy, że pomimo własnego doświadczenia jesteś osobą otwartą na perspektywę innych. Chcemy podzielić się tym, co&nbsp;wiemy o systemach CRM bo wierzymy, że możemy dostarczyć Ci dawkę wiedzy i inspiracji do rozwoju organizacji. 
</p>
</div>
</div>
</Container>
<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
        <h3>Wdrożenie kultury pracy z CRM</h3>

<p>Zaczęliśmy w czasie pandemii, jednym z pierwszych materiałów, nagranych w domu, ale naszym zdaniem - ogromnie wartościowym jest przygotowany vlog przez CEO Questy, Łukasza Tadyszaka, o skutecznym wdrożeniu CRM-a i zmiany w organizacji. 
</p>
<p>Ponad 20 lat doświadczenia skondensowane w jednym materiale. Wszystko po to, abyś mógł/mogła nas poznać i dowiedzieć się wszystkiego, co&nbsp;niezbędne o wdrożeniach takich rozwiązań. 
</p>
</div>
         <div className="text-block">
            <LiteYouTubeEmbed id="uUOkTv49Lqg" />
          </div>
</div>
</Container>

<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
        <h3>Integracja CRM z systemami ERP</h3>

<p>
Nasze systemy CRM integrują się z popularnymi na rynku systemami ERP, w tym np. Subiekt GT, Subiekt Nexo, Sage Symfonia Handel, Sage Symfonia ERP, Comarch Optima, Comarch ERP XL, WAPRO Mag, Navireo, Enova. W przypadku dedykowanego rozwiązania ten zakres jest większy np. Microsoft Dynamics, IFS ERP i wiele innych. Często tworzymy też nowe integracje, więc nie zniechęcaj się, jeżeli nie znalazłeś  na tej liście swojego systemu.
</p>
<p>
Piotr Szudrzyński, Business Devepolment Manager w Questy, opowiada o tym jak wygląda proces integracji i wyjaśnia jakie dane są wymieniane pomiędzy systemami
</p>
</div>
   <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed id="a1S10rsTsb0" />
          </div>
</div>
</Container>

<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
    <h3> Zarządzanie procesem sprzedaży</h3>

<p>Mistrzem wszelkich nagrywek jest u nas Marta Kaźmierska, Business Development Manager, która odpowiada za wdrożenia systemu Synergius CRM. Nagrała ona odcinek o projektowaniu procesu sprzedaży i korzyściach z tym związanych. 
</p>
<p>
Jeśli chcesz dowiedzieć się: 
<ul>
<li>Czym jest proces sprzedaży?</li>
<li>Dlaczego warto projektować skalowalny proces sprzedaży?</li>
<li>Dlaczego warto definiować cele i zadania na konkretnych etapach procesu?</li>
<li>Jak mierzyć wyniki i analizować dany proces?</li>
</ul>
…to poświęć 30 minut, aby posłuchać Marty, która ma kilkunastoletnie doświadczenie w tej branży!
</p>
</div>
    <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="_zpw8pqTHqk"
              title="Jak dobrze zaprojektować proces sprzedaży?"
            />
          </div>
</div>
</Container>

<Container className="container container--text">
    <div className="text-answers">
         <div className="text-block">
 <h3>...I rozmowy o CRM-ie</h3>
<p>W tym materiale Joanna Cieluch, Marketing Manager rozmawia z Łukaszem Tadyszakiem, CEO Questy, o tym czym jest CRM i od czego zależy sukces wdrożenia. 
</p>
<p>Jeśli masz więc kilkanaście minut zachęcamy Cię do obejrzenia vloga. W&nbsp;kolejnym odcinku będziemy mówić o przyczynach nieudanych wdrożeń i&nbsp;sposobach, aby ustrzec się przed tą sytuacją , obserwuj nas jeśli nie chcesz ominąć tego odcinka!
</p>
<p>Jeżeli chcesz zobaczyć więcej odwiedź nasz kanał na YT - aby to zrobić kliknij <strong><a href="https://www.youtube.com/user/QuestyHowTo/playlists">tutaj</a></strong>.</p>

</div>
     <div className="text-block" style={{
                paddingTop: "30px",
              }}>
            <LiteYouTubeEmbed
          poster="maxresdefault"
          id="uqoYcMSRL9A"
          title="Co to jest CRM: sama instalacja WYSTARCZY? Wywiad z Łukaszem Tadyszakiem"
        />
</div>
</div>
</Container>

  <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
      }}
    >
      <div className="container container--text">
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz bezpłatnie wypróbować system CRM w Twojej firmie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i wejść na wyższy poziom sprzedaży
            </Link>
          </div>
          </div>
    </section>
    </Layout>
  );
};

export default Aura;
